﻿.iti {
    display: block;
    margin: 0 0 1rem;
}

.iti__flag {
    background-image: url("/images/flags.png");
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .iti__flag {
        background-image: url("/images/flags@2x.png");
    }
}
