﻿.strikethrough-container {
    position: relative;

    &::before {
        content: "";
        display: block;
        width: 100%;
        height: 1px;
        background-color: $black;
        position: absolute;
        left: 0;
        top: 50%;
        z-index: 0;
    }
}

.strikethrough-container__text {
    display: inline-block;
    background-color: $white;
    padding: 0 20px;
    position: relative;
    z-index: 1;
    font-weight: bold;
}

.login-link-container {
    margin-top: 50px;
}

.login-link-container__link {
    font-weight: bold;
    text-decoration: underline;
}
