﻿.header {
    margin: 30px 0;
}

.header__link {
    display: block;
    margin: 0 auto;
    width: 150px;
}

.header__logo {
}
