﻿.profile-photo {
}

.profile-photo__image {
    max-width: 100px;
    max-height: 100px;
    border-radius: 50px;
    /*border: solid 2px #444;*/
}

.profile-photo__upload-form {
    display:inline-block;
}

.profile-photo__remove-form {
    display: inline-block;
}

.profile-photo__remove-button {
    color: get-color(primary);
    cursor: pointer;
}