﻿.authenticated-header {
}

.authenticated-header__top {
    background-color: #2a3d52;
    padding: 10px 0;

    @include breakpoint(small only) {
        text-align: center;
    }
}

.authenticated-header__bottom {
    background-color: #f8f5f2;
    font-size: 15px;
    font-weight: bold;
    padding: 28px 0;

    @include breakpoint(small only) {
        padding: 10px 0;
    }
}


.authenticated-header__link {
}

.authenticated-header__logo {
    height: 45px;
}
