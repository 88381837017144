﻿
.input-validation-error {
    border-color: get-color(alert) !important;
}

.required {
    color: get-color(alert);
}

.message {
    color: get-color(primary);
    font-weight: bold;
}

.error-message {
    color: get-color(alert);
    font-weight: bold;

    ul {
        list-style: none;
        margin-left: 0;
    }
}

.status-message {
    border: 2px solid get-color(warning);
    padding: 5px 5px 5px 30px;
    position: relative;
}

.status-message__icon {
    font-weight: bold;
    position: absolute;
    top: 5px;
    left: 5px;
}

.signout-iframe {
    display: none;
}
