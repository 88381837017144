﻿.side-nav {
    list-style: none;
}

.side-nav__item {
    &:first-child {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }

    &:last-child {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        border-bottom: solid 1px #c1c6c8;
    }

    border-top: solid 1px #c1c6c8;
    border-left: solid 1px #c1c6c8;
    border-right: solid 1px #c1c6c8;
    color: #2a3d52;
}

.side-nav__item--selected {
    background-color: #2a3d52;
    color: #fff;
}

.side-nav__link {
    &:hover {
        color: inherit;
    }
    display: block;
    color: inherit;
    line-height: 40px;
    padding-left: 5px;
    font-size: 14px;
    font-weight: bold;
}